<template>

  <div class="container
              page-container
              is-flex
              is-flex-wrap-wrap
              is-justify-content-center
              is-align-items-center">

    <div style="width: 35%; min-width: 300px;">

      <div class="mb-6">
        <p class="has-text-grey
                  has-text-centered
                  is-size-5
                  is-size-6-mobile mb-1">
          Choisissez un nouveau mot de passe
        </p>
      </div>

      <b-field label="Nouveau mot de passe"
               :type="{ 'is-danger': $v.password.$error }"
               :message="[
                 { 'Champs requis': !$v.password.required },
                 { 'Minimum 10 caractères': !$v.password.minLength },
               ]"
               v-show="!showSuccessMessage">
        <b-input type="password"
                 v-model.trim="$v.password.$model"
                 password-reveal
                 ref="passInput"
                 @keyup.enter.native="reset">
        </b-input>
      </b-field>

      <b-field label="Confirmation mot de passe"
              :type="{ 'is-danger': $v.passwordConfirmation.$error }"
              :message="[
                {
                  'Les deux mots de passe doivent être identiques':
                  $v.passwordConfirmation.$error
                },
              ]"
              v-show="!showSuccessMessage">
        <b-input type="password"
                 v-model.trim="$v.passwordConfirmation.$model"
                 password-reveal
                 @keyup.enter.native="reset">
        </b-input>
      </b-field>

      <b-notification
        type="is-dark"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        closable
        class="mt-4"
        v-model="active">
        {{ error }}
      </b-notification>

      <b-button type="is-primary"
                :disabled="$v.$invalid"
                expanded
                @click="reset"
                v-if="!showSuccessMessage"
                class="is-primary mt-6">
        Réinitialiser mon mot de passe
      </b-button>

      <b-notification
        type="is-success"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        :closable="false"
        class="mt-4"
        has-icon
        v-model="showSuccessMessage">
        <p>Modification du mot de passe effectuée succès !</p>
      </b-notification>

      <b-button type="is-primary"
                tag="router-link"
                expanded
                to="/signin"
                v-if="showSuccessMessage"
                icon-left="chevron-left">
        Retourner à la page d'authentification
      </b-button>

    </div>

  </div>

</template>

<script>
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  data: () => ({
    password: '',
    passwordConfirmation: '',
    showSuccessMessage: false,
    error: null,
    active: false,
  }),
  validations: {
    password: {
      required,
      maxLength: maxLength(64),
      minLength: minLength(10),
    },
    passwordConfirmation: {
      sameAsPassword: sameAs('password'),
    },
  },
  mounted() {
    this.$refs.passInput.focus();
  },
  methods: {
    async reset() {
      if (!this.disabled) {
        const loadingComponent = this.$buefy.loading.open({
          container: null,
        });
        const { token } = this.$route.query;
        const payload = {
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
        };

        if (this.password !== this.passwordConfirmation) {
          loadingComponent.close();
          this.error = 'Veuillez saisir deux mots de passe identiques.';
          this.active = true;
          return;
        }

        this.active = false;
        try {
          await this.$http.post(
            `${process.env.VUE_APP_API_URL}/auth/password/reset/${token}`,
            payload,
          );
          this.showSuccessMessage = true;
        } catch (err) {
          const { status } = err.response;
          let message = '';

          switch (status) {
            case 404:
              message = 'Votre lien de réinitialisation est invalide ou a expiré.';
              break;
            case 429:
              message = 'Nombre d\'essais autorisés dépassé, veuillez réessayer dans une heure.';
              break;
            default:
              message = 'Une erreur est survenue, nous n\'avons malheureusement pas pu répondre à votre demande.';
          }
          this.error = message;
          this.active = true;
        } finally { loadingComponent.close(); }
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
</style>